<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.date') }}</span></div>
           <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" v-model:endDefault="endDefault" :withToday="withToday"/>
         </div>
         <div class="box-ui-select searchbar-content radioBtn">
          <div class="title"><span>{{ $t('common.allapply') }}</span></div>
          <input type="radio" value="isAll" v-model="selectedOption" checked>
        </div>
        <div class="box-ui-select searchbar-content radioBtn">
          <div class="title"><span>{{ $t('searchArea.idlexcept') }}</span></div>
          <input type="radio" value="isBS" v-model="selectedOption">
        </div>
        <div class="box-ui-select searchbar-content radioBtn">
          <div class="title"><span>{{ $t('searchArea.idlAppli') }}</span></div>
          <input type="radio" value="isDiff" v-model="selectedOption">
        </div>
      </div>
      <div class="searchBar">
        <div class="box-ui-select searchbar-content">
          <div class="title"><span>{{ $t('common.sort') }}</span></div>
          <select v-model="reqData.orderStr">
            <option :value="''">{{ $t('table.head.index') }}</option>
            <option :value="'BET_DESC'">{{ $t('searchArea.highBet') }}</option>
            <option :value="'BET_ASC'">{{ $t('searchArea.lowBet') }}</option>
            <option :value="'WIN_DESC'">{{ $t('searchArea.highWin') }}</option>
            <option :value="'WIN_ASC'">{{ $t('searchArea.lowWin') }}</option>
          </select>
        </div>
       <!-- <div class="box-ui-select searchbar-content">
         <ui-check :id="'isBS'" :text="$t('searchArea.idlingbtn')" :class="'ui-check'" :checked="isBS" @setYn="onChangeBS"/>
       </div> -->
        <div :class="'searchbar-content'">
           <div class="title"><span>{{ $t('table.head.category') }}</span></div>
           <div>
              <select class="mr-5" v-model="searchType">
                <option value="memId">{{ $t('searchArea.id') }}</option>
                <option value="recommenderId">{{ $t('table.head.recommenderId') }}</option>
              </select>
              <input type="text" class="mr-5" v-model="searchId"/>
              <button class="btn-search" type="button" @click="setTableData(1)">
                <i class="fas fa-search"></i>
              </button>
           </div>
        </div>
      </div>
      <memo></memo>
    </div>
    <h3 class="tableName">{{ $t('searchArea.schResultTotal') }}</h3>
    <div class="totalBox min-w1600" v-if="searchTotal">
      <table class="mainTable fixed">
        <!-- <colgroup>
          <col>
          <col>
          <col>
          <col>
          <col>
          <col v-if="gameGroupList.includes('casino')">
          <col v-if="gameGroupList.includes('slot')">
          <col v-if="gameGroupList.includes('minigame')">
          <col>
        </colgroup> -->
        <tbody>
        <tr>
          <td class="money">
            <ul class="money">
              <li><em>{{ $t('table.head.member') }}</em><span>{{ numberWithCommas(searchTotal.cntBottom) }}</span></li>
              <li><em>{{ $t('table.body.money') }}</em><span>{{ numberWithCommas(searchTotal.cashAmt) }}</span></li>
              <li><em>{{ $t('table.body.pointAmt') }}</em><span>{{ numberWithCommas(searchTotal.pointAmt) }}</span></li>
            </ul>
          </td>
          <td>
            <ul class="money">
              <li><em>{{ $t('table.body.userIn') }}</em><span>{{ numberWithCommas(searchTotal.userCashIn) }}</span></li>
              <li><em>{{ $t('table.body.partnerIn') }}</em><span>{{ numberWithCommas(searchTotal.userPayment) }}</span></li>
              <li><em>{{ $t('table.body.adminIn') }}</em><span>{{ numberWithCommas(searchTotal.adminPayment) }}</span></li>
            </ul>
          </td>
          <td>
            <ul class="money">
              <li><em>{{ $t('table.body.userOut') }}</em><span>{{ numberWithCommas(searchTotal.userCashOut) }}</span></li>
              <li><em>{{ $t('table.body.partnerOut') }}</em><span>{{ numberWithCommas(searchTotal.userPayback) }}</span></li>
              <li><em>{{ $t('table.body.adminOut') }}</em><span>{{ numberWithCommas(searchTotal.adminPayback) }}</span></li>
            </ul>
          </td>
          <td>
            <ul class="money">
              <li><em>{{ $t('table.body.in_out') }}</em><span>{{ numberWithCommas(searchTotal.userCashResultAmt) }}</span></li>
              <li><em>{{ $t('table.body.partnerin_out') }}</em><span>{{ numberWithCommas(searchTotal.partnerResultAmt) }}</span></li>
              <li><em>{{ $t('table.body.adminin_out') }}</em><span>{{ numberWithCommas(searchTotal.adminResultAmt) }}</span></li>
            </ul>
          </td>
          <td v-if="gameGroupList.includes('casino')">
            <ul class="money">
              <li class="head border-bot"><span>{{ $t('table.head.casino') }}</span></li>
              <li class="border-bot">
                <em>{{ $t('common.bet') }}</em>
                <!-- <span>{{ numberWithCommas(!isBS ? searchTotal.casinoBetAmt : searchTotal.casinoBetAmtBS) }}</span> -->
                <span v-if="isAll">{{ numberWithCommas(searchTotal.casinoBetAmt) }}</span>
                <span v-if="isBS">{{ numberWithCommas(searchTotal.casinoBetAmtBS) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(searchTotal.casinoBetAmt - searchTotal.casinoBetAmtBS) }}</span>
              </li>
              <li class="border-bot">
                <em>{{ $t('common.win') }}</em>
                <!-- <span>{{ numberWithCommas(!isBS ? searchTotal.casinoBetAmtWin : searchTotal.casinoBetAmtWinBS) }}</span> -->
                <span v-if="isAll">{{ numberWithCommas(searchTotal.casinoBetAmtWin) }}</span>
                <span v-if="isBS">{{ numberWithCommas(searchTotal.casinoBetAmtWinBS) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(searchTotal.casinoBetAmtWin - searchTotal.casinoBetAmtWinBS) }}</span>
              </li>
              <li class="border-bot">
                <em>{{ $t('common.rolling') }}</em>
                <!-- <span>{{ numberWithCommas(searchTotal.casinoRollingAmt) }}</span> -->
                <span v-if="isAll">{{ numberWithCommas(searchTotal.casinoRollingAmt) }}</span>
                <span v-if="isBS">{{ numberWithCommas(searchTotal.casinoRollingAmt) }}</span>
                <span v-if="isDiff">0</span>
              </li>
              <li><em>{{ $t('common.losing') }}</em><span>-</span></li>
              <li class="last"><em>{{ $t('common.cal') }}</em>
                <!-- <span v-if="!isBS"
                    :class="{'rdc' : Number(searchTotal.casinoBetResultAmt) > 0, 'blc': Number(searchTotal.casinoBetResultAmt) <= 0}">
                          {{ numberWithCommas(searchTotal.casinoBetResultAmt) }}
                        </span>
                <span v-else
                  :class="{'rdc' : Number(searchTotal.casinoBetResultAmtBS) > 0, 'blc': Number(searchTotal.casinoBetResultAmtBS) <= 0}">
                          {{ numberWithCommas(searchTotal.casinoBetResultAmtBS) }}
                        </span> -->
                <span v-if="isAll">{{ numberWithCommas(searchTotal.casinoBetResultAmt) }}</span>
                <span v-if="isBS">{{ numberWithCommas(searchTotal.casinoBetResultAmtBS) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(searchTotal.casinoBetResultAmt - searchTotal.casinoBetResultAmtBS) }}</span>
              </li>
            </ul>
          </td>
          <td v-if="gameGroupList.includes('sport')">
            <ul class="money">
              <li class="head border-bot"><span>{{ $t('table.head.sport') }}</span></li>
              <li class="border-bot"><em>{{ $t('common.bet') }}</em><span>{{ numberWithCommas(searchTotal.sportBetAmt) }}</span></li>
              <li class="border-bot"><em>{{ $t('common.win') }}</em><span>{{ numberWithCommas(searchTotal.sportBetAmtWin) }}</span></li>
              <li class="border-bot"><em>{{ $t('common.rolling') }}</em><span>{{ numberWithCommas(searchTotal.sportRollingAmt) }}</span></li>
              <li><em>{{ $t('common.losing') }}</em><span>-</span></li>
              <li class="last"><em>{{ $t('common.cal') }}</em>
                <span
                  :class="{'rdc' : Number(searchTotal.sportBetResultAmt) > 0, 'blc': Number(searchTotal.sportBetResultAmt) <= 0}">
                          {{ numberWithCommas(searchTotal.sportBetResultAmt) }}
                        </span>
              </li>
            </ul>
          </td>
          <td v-if="gameGroupList.includes('slot')">
            <ul class="money">
              <li class="head border-bot"><span>{{ $t('table.head.slot') }}</span></li>
              <li class="border-bot">
                <em>{{ $t('common.bet') }}</em>
                <!-- <span>{{ numberWithCommas(!isBS ? searchTotal.slotBetAmt : searchTotal.slotBetAmtBS) }}</span> -->
                <span v-if="isAll">{{ numberWithCommas(searchTotal.slotBetAmt) }}</span>
                <span v-if="isBS">{{ numberWithCommas(searchTotal.slotBetAmtBS) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(searchTotal.slotBetAmt - searchTotal.slotBetAmtBS) }}</span>
              </li>
              <li class="border-bot">
                <em>{{ $t('common.win') }}</em>
                <!-- <span>{{ numberWithCommas(!isBS ? searchTotal.slotBetAmtWin : searchTotal.slotBetAmtWinBS) }}</span> -->
                <span v-if="isAll">{{ numberWithCommas(searchTotal.slotBetAmtWin) }}</span>
                <span v-if="isBS">{{ numberWithCommas(searchTotal.slotBetAmtWinBS) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(searchTotal.slotBetAmtWin - searchTotal.slotBetAmtWinBS) }}</span>
              </li>
              <li class="border-bot">
                <em>{{ $t('common.rolling') }}</em>
                <!-- <span>{{ numberWithCommas(searchTotal.slotRollingAmt) }}</span> -->
                <span v-if="isAll">{{ numberWithCommas(searchTotal.slotRollingAmt) }}</span>
                <span v-if="isBS">{{ numberWithCommas(searchTotal.slotRollingAmt) }}</span>
                <span v-if="isDiff">0</span>
              </li>
              <li><em>{{ $t('common.losing') }}</em><span>-</span></li>
              <li class="last"><em>{{ $t('common.cal') }}</em>
                <!-- <span v-if="!isBS"
                    :class="{'rdc' : Number(searchTotal.slotBetResultAmt) > 0, 'blc': Number(searchTotal.slotBetResultAmt) <= 0}">
                          {{ numberWithCommas(searchTotal.slotBetResultAmt) }}
                        </span>
                <span v-else
                      :class="{'rdc' : Number(searchTotal.slotBetResultAmtBS) > 0, 'blc': Number(searchTotal.slotBetResultAmtBS) <= 0}">
                          {{ numberWithCommas(searchTotal.slotBetResultAmtBS) }}
                        </span> -->
                <span v-if="isAll">{{ numberWithCommas(searchTotal.slotBetResultAmt) }}</span>
                <span v-if="isBS">{{ numberWithCommas(searchTotal.slotBetResultAmtBS) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(searchTotal.slotBetResultAmt - searchTotal.slotBetResultAmtBS) }}</span>
              </li>
            </ul>
          </td>
          <td v-if="gameGroupList.includes('minigame')">
            <ul class="money">
              <li class="head border-bot"><span>{{ $t('table.head.mini') }}</span></li>
              <li class="border-bot"><em>{{ $t('common.bet') }}</em><span>{{ numberWithCommas(searchTotal.miniBetAmt) }}</span></li>
              <li class="border-bot"><em>{{ $t('common.win') }}</em><span>{{ numberWithCommas(searchTotal.miniBetAmtWin) }}</span></li>
              <li class="border-bot"><em>{{ $t('common.rolling') }}</em><span>{{ numberWithCommas(searchTotal.miniRollingAmt) }}</span></li>
              <li><em>{{ $t('common.losing') }}</em><span>-</span></li>
              <li class="last"><em>{{ $t('common.cal') }}</em>
                <span
                    :class="{'rdc' : Number(searchTotal.miniBetResultAmt) > 0, 'blc': Number(searchTotal.miniBetResultAmt) <= 0}">
                          {{ numberWithCommas(searchTotal.miniBetResultAmt) }}
                        </span>
              </li>
            </ul>
          </td>
          <td>
            <ul class="money">
              <li class="head border-bot"><span>{{ $t('common.total') }}</span></li>
              <li class="border-bot">
                <em>{{ $t('table.body.totalBetAmts') }}</em>
                <!-- <span>{{ numberWithCommas(!isBS ? searchTotal.betAmt : searchTotal.betAmtBS) }}</span> -->
                <span v-if="isAll">{{ numberWithCommas(searchTotal.betAmt) }}</span>
                <span v-if="isBS">{{ numberWithCommas(searchTotal.betAmtBS) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(searchTotal.betAmt - searchTotal.betAmtBS) }}</span>
              </li>
              <li class="border-bot">
                <em>{{ $t('table.body.totalWinAmts') }}</em>
                <!-- <span>{{ numberWithCommas(!isBS ? searchTotal.betAmtWin : searchTotal.betAmtWinBS) }}</span> -->
                <span v-if="isAll">{{ numberWithCommas(searchTotal.betAmtWin) }}</span>
                <span v-if="isBS">{{ numberWithCommas(searchTotal.betAmtWinBS) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(searchTotal.betAmtWin - searchTotal.betAmtWinBS) }}</span>
              </li>
              <li class="border-bot">
                <em>{{ $t('table.body.totalRoll') }}</em>
                <!-- <span>{{ numberWithCommas(searchTotal.rollingAmt) }}</span> -->
                <span v-if="isAll">{{ numberWithCommas(searchTotal.rollingAmt) }}</span>
                <span v-if="isBS">{{ numberWithCommas(searchTotal.rollingAmt) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(searchTotal.rollingAmt - searchTotal.rollingAmt) }}</span>
              </li>
              <li><em>{{ $t('table.body.totalLose') }}</em><span>-</span></li>
              <li class="last"><em>{{ $t('table.body.totalCalAmt') }}</em>
                <span v-if="isAll">{{ numberWithCommas(searchTotal.betResultAmt) }}</span>
                <span v-if="isBS">{{ numberWithCommas(searchTotal.betResultAmtBS) }}</span>
                <span v-if="isDiff">{{ numberWithCommas(searchTotal.betResultAmt - searchTotal.betResultAmtBS) }}</span>
                <!-- <span v-if="!isBS"
                    :class="{'rdc' : Number(searchTotal.betResultAmt) > 0, 'blc': Number(searchTotal.betResultAmt) <= 0}">
                          {{ numberWithCommas(searchTotal.betResultAmt) }}
                        </span>
                <span v-else
                      :class="{'rdc' : Number(searchTotal.betResultAmtBS) > 0, 'blc': Number(searchTotal.betResultAmtBS) <= 0}">
                          {{ numberWithCommas(searchTotal.betResultAmtBS) }}
                        </span> -->
              </li>
            </ul>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="main-contents-wrapper min-w1600">
      <article class="total-info-container">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ searchList ? searchList.length:0 }}</span>
          </div>
          <!--          <div class="total-info-item">-->
          <!--            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>-->
          <!--          </div>-->
          <!--          <div class="total-info-item">-->
          <!--            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>-->
          <!--          </div>-->
        </div>
      </article>
      <div class="table-wrapper">
        <all-to-table v-if="searchList"
                      :table="'main'"
                      :headInfo="headInfo"
                      :list="searchList"
                      :reqData="reqData" :key="reqData.endDate"
                      :level="0"
                      :isBS="isBS"
                      :isAll="isAll"
                      :isDiff="isDiff"
                      :type="'user'"
        />
      </div>
    </div>
    <pagination v-if="searchList && searchList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import lodash from 'lodash'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import TableHead from '@/components/main/table/Head.vue'
import UiCheckSet from '@/components/ui/UiCheckSet'
import UiCheck from '@/components/ui/UiCheck'
import subTitle from '@/components/main/PageSubTitle'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import { calculateSearch, calculateSearchByUser } from '@/api/report'
import AllToTable from '@/components/ui/report/allToTable.vue'
import { addDays } from 'date-fns'
import { mapState } from 'vuex'
import Memo from '@/components/common/memo'

export default {
  name: 'ReportTrade',
  inheritAttrs: false,
  components: {
    AllToTable,
    SearchFilter,
    DateSelector,
    TableHead,
    UiCheckSet,
    UiCheck,
    subTitle,
    Pagination,
    Memo
  },
  data: function () {
    return {
      headInfo: {
        fstColumn: false,
        dataList: ['memName', 'slotRate', 'casinoRate', 'miniRate', 'member', 'allBet', 'money', 'cashDiff', 'casino', 'slot', 'minigame']
      },
      reqData: {
        page: 1,
        count_per_list: '30',
        searchMemId: '',
        chkTodayYn: 'Y',
        startDate: '',
        endDate: '',
        orderStr: ''
      },
      searchType: 'memId',
      searchId: '',
      pageInfo: {
        tatal_list_count: 0,
        page: 0,
        tatal_page_count: 0
      },
      searchList: null,
      searchTotal: {},
      startDefault: '',
      endDefault: '',
      moreToggle: false,
      totalText: false,
      withToday: false,
      isBS: false,
      isAll: true,
      isDiff: false,
      selectedOption: 'isAll'
    }
  },
  watch: {
    selectedOption: function(newOption, oldOption) {
      this.isBS = false
      this.isAll = false
      this.isDiff = false
      this[newOption] = true
    }
  },
  computed: {
    ...mapState([
      'gameGroupList'
    ])
  },
  methods: {
    onChangeBS (value) {
      this.isBS = value === 'Y'
    },
    todayCheck () {
      let endDate = ''
      if (this.$refs.today.checked) {
        this.reqData.chkTodayYn = 'Y'
        this.withToday = true
        endDate = new Date()
      } else {
        this.reqData.chkTodayYn = 'N'
        endDate = addDays(new Date(), -1)
        this.withToday = false
      }
      // console.log(endDate)
      // this.endDefault = endDate
      // this.reqData.endDate = getDateStr(endDate, "yyyy-MM-dd");
      // // this.reqData.endDate = getDateStr(this.endDefault, "yyyy-MM-dd");

      this.setTableData()
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = addDays(new Date(), 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    setEndDate (date) {
      console.log('setEndDate : ', date)
      let _date = ''
      if (!date) {
        _date = addDays(new Date(), 0)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    async setTableData (page) {
      window.scrollTo(0, 0)
      if (!page) {
        page = 1
      }
      this.reqData.page = page
      this.emitter.emit('Loading', true)
      const today = getDateStr(new Date(), 'yyyy-MM-dd')
      if (this.reqData.endDate === today) {
        this.reqData.chkTodayYn = 'Y'
      } else {
        this.reqData.chkTodayYn = 'N'
      }

      if (this.searchType === 'memId') {
        this.reqData.searchMemId = this.searchId
        delete this.reqData.searchRecommandId
      } else {
        this.reqData.searchRecommandId = this.searchId
        delete this.reqData.searchMemId
      }

      console.log('[req] : ', this.reqData)
      const res = await calculateSearchByUser(this.reqData)
      console.log(res)
      if (res.resultCode === '0') {
        this.pageInfo = res.data.pageInfo
        this.searchList = res.data.searchList
        this.searchTotal = res.data.searchTotal

        this.searchList.forEach(item => {
          item.casinoLosingAmt = (item.casinoBetAmt - item.casinoBetAmtWin - item.casinoRollingAmt) * (item.casinoLoseRate * 0.01)
          item.slotLosingAmt = (item.slotBetAmt - item.slotBetAmtWin - item.slotRollingAmt) * (item.slotLoseRate * 0.01)
          item.miniLosingAmt = (item.miniBetAmt - item.miniBetAmtWin - item.miniRollingAmt) * (item.miniLoseRate * 0.01)
          item.sportLosingAmt = (item.sportBetAmt - item.sportBetAmtWin - item.sportRollingAmt) * (item.sportLoseRate * 0.01)
        })
      }
      this.emitter.emit('Loading', false)
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    if (this.$route.query.id) {
      this.reqData.memId = this.$route.query.id
    }
    this.setStartDate()
    this.setEndDate()
    await this.setTableData()
    this.emitter.emit('Loading', false)
  }
}
</script>
<style scoped>
  .blc {color: #0f84ee;font-weight: bold;}
  .rdc {color: #eb7a3f;font-weight: bold;}
  .searchBar {align-items: flex-end;}
  .ui-check {font-size:12px;}
  .tableName {padding: 20px 0 0; margin: 0 17px; font-size: 15px; font-weight: 600; color: #202020;}
  .totalBox {margin: 10px 17px; border: 3px solid #efefef; }
  .totalBox td:last-child, .mainTable td:last-child {border-right: 0;}
  .totalBox td {border-right: 3px solid #f6f6f6;padding: 0;}
  .totalBox td:last-child, .mainTable td:last-child {border-right: 0;}
  .border-bot {border-bottom: 3px solid #efefef;}   .border-bot {border-bottom: 3px solid #f6f6f6;}

  .mainTable >>> td {padding: 0;}
  .mainTable >>> .name {text-align: left;}
  .mainTable >>> .name span {margin-left: 20px;display: block;}
  .mainTable >>> tr:nth-child(even) td {background: #f7f7f7;}

  .table-wrapper {border:3px solid #efefef;padding: 0;}
  .mainTable td { width: 100%; }
  >>> .money {text-align: left;}
  >>> .money li {text-align: left;padding: 0 17px;height: 24px;display: flex;align-items: center;justify-content: space-between; font-size: 12px;}
  /* >>> .money li:nth-child(5) {background: #e4efee;} */
  >>> .money li.head {justify-content:center;font-weight: bold;}
  >>> .money li.last {background: #fdf5ed;}
  .radioBtn{
    flex-direction: row;
    align-items: center;
   }
</style>
